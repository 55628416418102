import type { ReactNode } from "react";

import { BREAKPOINTS } from "@goalteller/app-kit/ye-design/styles/media";
import Image from "next/image";
import { useWindowSize } from "react-use";

import bannerServiceA from "../../images/homePage/bannerA.svg";
import bannerServiceB from "../../images/homePage/bannerB.svg";
import bannerServiceC from "../../images/homePage/bannerC.svg";
import bannerServiceD from "../../images/homePage/bannerD.svg";
import bannerServiceE from "../../images/homePage/bannerE.svg";
import bannerImage from "../../images/homePage/bannerImage.webp";
import { BannerBgContainer } from "../wrappers/div";
import styles from "./homeBanner.module.css";

const DATA = [
  {
    heading: "Easy-to-use",
    image: bannerServiceA,
    subheading: "interface for hassle-free calculations",
  },
  {
    heading: "Multiple financial tools",
    image: bannerServiceB,
    subheading: "tools at your fingertips",
  },
  {
    heading: "Customizable options",
    image: bannerServiceC,
    subheading: "to suit your unique needs",
  },
  {
    heading: "Real-time",
    image: bannerServiceD,
    subheading: "data for accurate results.",
  },
  {
    heading: "Expert guidance",
    image: bannerServiceE,
    subheading: "and tips for financial success",
  },
];

function BannerTile({
  item,
}: {
  item: {
    heading: ReactNode;
    image: string;
    subheading: ReactNode;
  };
}) {
  return (
    <div className={styles.bannerTile} key={item.image}>
      <Image alt="person using calculator" src={item.image} />
      <div className={styles.bannerTileWrapper}>
        <div className={styles.bannerTileHeading}>{item.heading}</div>
        <div className={styles.bannerTileSubHeading}>{item.subheading}</div>
      </div>
    </div>
  );
}

export default function Banner() {
  const { width } = useWindowSize();
  return (
    <BannerBgContainer className={styles.root} isSection>
      <div className={styles.leftContainer}>
        <div className={styles.wrapper}>
          <div className={styles.headerWrapper}>
            <div className={styles.heading}>
              Welcome to <br />
              <span className={styles.headingPrimary}>Sahi</span>
              <span className={styles.headingSecondary}>
                Decision
              </span> <br /> Finance Calculator!
            </div>

            {width < BREAKPOINTS.sm && (
              <Image
                alt="person using calculator"
                className={styles.bannerImage}
                src={bannerImage}
              />
            )}
          </div>

          <div className={styles.subHeading}>
            Unlock the power of financial planning with our user-friendly
            Finance Calculator, Our calculator offers precise results to make
            informed financial decisions.
          </div>
        </div>
        {width >= BREAKPOINTS.sm && (
          <Image
            alt="person using calculator"
            className={styles.bannerImage}
            src={bannerImage}
          />
        )}
      </div>

      <div className={styles.rightContainer}>
        {width >= BREAKPOINTS.sm &&
          DATA.map((item, index) => <BannerTile item={item} key={index} />)}
      </div>
    </BannerBgContainer>
  );
}
