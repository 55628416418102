import HeadWrapper from "@goalteller/app-kit/components/head/NxHeadWrapper";

import FeatureCard from "../components/homePage/FeatureCard";
import HomeBanner from "../components/homePage/HomeBanner";

export default function SahiDecision() {
  return (
    <>
      <HeadWrapper
        description="Every financial decision you take, big or small has a big impact on your investments and financial planning. We ensure that every decision is thought of comprehensively"
        keywords="Financial Decisions, Financial Calculators Comprehensive, Informed, Calculators, Insurance, Kids planning"
        title="SahiDecision - We help you take Informed Financial Decisions "
      />
      <HomeBanner />
      <FeatureCard />
    </>
  );
}
